.Polaris-DropZone-FileUpload__Button {
  display: none;
}
.Polaris-DisplayText--sizeExtraLarge {
  text-align: center;
}

.Polaris-Modal-Dialog__Modal {
  /* background-color: transparent;
  box-shadow: none; */
}

.Polaris-Modal-CloseButton--withoutTitle {
  display: none;
}

.Polaris-ResourceList__EmptySearchResultWrapper {
  padding-bottom: 0px;
  padding-top: 0px;
}
.Polaris-EmptySearchResult__Image {
  width: 300px;
  height: 300px;
}
.Polaris-ButtonGroup {
  justify-content: center;
  flex-wrap: nowrap;
}

.spinnerContainer {
  height: 200px;
  width: 100%;
  position: relative;
  display: table;
}

.vertical-center {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.Polaris-TopBar {
  background-color: rgba(100, 100, 100, 1);
}
.Polaris-TopBar-UserMenu__Name {
  color: white;
}
.Polaris-Modal-CloseButton {
  display: none;
}
.stickyHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  padding-top: 50px;
  z-index: 1;
}
.Polaris-DataTable__Navigation {
  /* z-index: 1;
    height: 60px;
    background-color: transparent;
    position: fixed; */
}
.Polaris-DataTable__Cell .Polaris-ButtonGroup {
  float: right;
}

.MuiPaginationItem-sizeLarge {
  font-size: 1.2rem !important;
}
.MuiPaginationItem-sizeLarge .MuiPaginationItem-icon {
  font-size: 2rem !important;
}
.MuiPagination-ul {
  display: inline-flex !important;
}
.Polaris-DropZone--sizeExtraLarge {
  min-height: 8rem !important;
  max-height: 8rem !important;
}
